/* eslint-disable no-restricted-globals */
import { BadgeManageModal } from '@component/database/badge/BadgeManageModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import { useUserBadges } from '@hook/useUserBadges';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Input,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import { UserIdContext } from '@page/user/UserDetailPage';
import { Search } from 'lucide-react';
import { useContext } from 'react';

import { UserBadgesAddModal } from './modal/UserBadgesAddModal';

export const UserBadgeTab = () => {
  const { userId } = useContext(UserIdContext);
  const { badges, postUserBadge, deleteUserBadge } = useUserBadges({ userId });
  const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } =
    useSearchAndPagination(badges, (badge, SearchValue) => badge.name.includes(SearchValue));

  const { isOpen, onClose, onOpenChange } = useDisclosure();

  return (
    <div className='flex min-h-[80%] flex-col'>
      <UserBadgesAddModal
        isOpen={isOpen}
        onClose={onClose}
        onOpenChange={onOpenChange}
        onSave={postUserBadge}
        userBadges={badges}
        userId={userId}
      />

      <Card className='grow'>
        <CardHeader>
          <h1 className='text-2xl'>뱃지 관리</h1>
        </CardHeader>
        <Divider />
        <CardBody className='flex flex-col gap-4'>
          <Table
            layout='auto'
            topContent={
              <div className='flex flex-col gap-4'>
                <div className='flex w-full flex-row justify-between'>
                  <div className='flex max-w-sm flex-1 flex-row items-center'>
                    <Input
                      labelPlacement='outside'
                      startContent={<Search />}
                      value={filterValue}
                      onClear={() => onClear()}
                      onValueChange={onSearchChange}
                    />
                  </div>
                  <Button
                    color='primary'
                    onPress={onOpenChange}
                  >
                    뱃지 추가
                  </Button>
                </div>
              </div>
            }
            topContentPlacement='outside'
          >
            <TableHeader>
              <TableColumn key='name'>뱃지 이름</TableColumn>
              <TableColumn key='image'>뱃지 이미지</TableColumn>
              <TableColumn key='setting'>비고</TableColumn>
            </TableHeader>
            <TableBody items={items}>
              {(badge) => (
                <TableRow
                  className='relative cursor-pointer'
                  key={badge.id}
                  // onClick={() => openBadgeUpdateModal(badge)}
                >
                  <TableCell>{badge.name}</TableCell>
                  <TableCell>
                    <Image
                      src={badge.imageUrl}
                      alt={badge.name}
                      width={50}
                      height={50}
                    />
                  </TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          isIconOnly
                          size='sm'
                          variant='light'
                        >
                          <VerticalDotsIcon className='text-default-300' />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu>
                        <DropdownItem
                          key='delete'
                          className='text-red-500'
                          onPress={() => {
                            if (!confirm('정말 삭제하시겠습니까?')) return;
                            deleteUserBadge({ id: badge.id });
                          }}
                        >
                          삭제
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <div className='flex w-full flex-col items-center'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(filteredItems.length / 10), 1)}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
