import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { badgeApi } from '@simppl/repository/badge';
import type {
  DeleteBadgesReqType,
  PatchBadgesReqType,
  PostBadgesReqType,
} from '@simppl/repository/badge/req';
import type { GetBadgesResType } from '@simppl/repository/badge/res';
import { useState } from 'react';

const GET_BADGES_QUERY_KEY = 'get-badges';

export const useBadges = () => {
  const queryClient = useQueryClient();

  const { data: badges } = useSuspenseQuery({
    queryKey: [GET_BADGES_QUERY_KEY],
    queryFn: badgeApi.GET,
  });

  const [badgeModalData, setBadgeModalData] = useState<
    { mode: 'create' } | { mode: 'update'; data: GetBadgesResType['data'][0] }
  >({ mode: 'create' });

  const invalidateGetBadges = () => {
    queryClient.invalidateQueries([GET_BADGES_QUERY_KEY]);
  };

  const postBadgeHook = useMutation(badgeApi.POST);
  const patchBadgeHook = useMutation(badgeApi.PATCH);
  const deleteBadgeHook = useMutation(badgeApi.DELETE);

  const postBadge = (body: PostBadgesReqType) =>
    postBadgeHook.mutate(body, { onSuccess: invalidateGetBadges });

  const patchBadge = (body: PatchBadgesReqType) =>
    patchBadgeHook.mutate(body, { onSuccess: invalidateGetBadges });

  const deleteBadge = ({ id }: DeleteBadgesReqType) =>
    deleteBadgeHook.mutate(
      { id },
      {
        onSuccess: invalidateGetBadges,
      },
    );

  const onSaveBadgeModal = (data: PatchBadgesReqType | PostBadgesReqType) => {
    switch (badgeModalData.mode) {
      case 'create':
        postBadge(data);
        break;
      case 'update':
        if ('id' in data) patchBadge(data);
        break;
      default:
        alert('개발자에게 문의하세요.');
    }
  };

  return { badges, onSaveBadgeModal, deleteBadge, setBadgeModalData, badgeModalData };
};
