/* eslint-disable no-shadow */
// import { UploadFile } from '@component/ScoreFileUploader';
import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
} from '@nextui-org/react';
import FileUploader from '@simppl/component/FileUploader';
import type { PatchBadgesReqType, PostBadgesReqType } from '@simppl/repository/badge/req';
import { useState } from 'react';

type BadgeManageModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
  onSave: (data: PostBadgesReqType | PatchBadgesReqType) => void;
  id?: string;
  data?: PatchBadgesReqType;
};

export const BadgeManageModal = ({
  isOpen,
  onOpenChange,
  onClose,
  onSave,
  data,
}: BadgeManageModalProps) => {
  const [badgeName, setBadgeName] = useState(data?.name || '');
  const [badgeImageName, setBadgeImageName] = useState('');
  const [badgeImageUrl, setBadgeImageUrl] = useState(data?.imageUrl || '');
  const [imagePreviewUrl, setImagePreviewUrl] = useState(data?.imageUrl || '');

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalContent>
        <>
          <ModalHeader className='flex flex-col gap-1'>뱃지 관리</ModalHeader>
          <ModalBody>
            <div className='flex flex-col gap-4'>
              <div className='flex flex-col'>
                <p>뱃지 이름</p>
                <Textarea
                  placeholder='뱃지 이름을 적어주세요.'
                  value={badgeName}
                  onValueChange={setBadgeName}
                />
              </div>
              <FileUploader
                accept='image/png, image/svg+xml, image/jpeg, image/jpg'
                setFileName={setBadgeImageName}
                setFileUrl={setBadgeImageUrl}
                content={badgeImageName}
                repository='badges'
                setLocalFileUrl={(url) => setImagePreviewUrl(url)}
              />

              {imagePreviewUrl && (
                <div>
                  <Image
                    src={imagePreviewUrl}
                    alt={badgeImageName}
                  />
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color='danger'
              variant='light'
              onPress={onClose}
            >
              취소
            </Button>
            <Button
              type='submit'
              form='authorization'
              color='primary'
              onPress={() => {
                if (!badgeName || !badgeImageUrl) {
                  alert('뱃지 이름, 이미지는 필수 항목입니다');
                  return;
                }
                if (!data?.id) {
                  onSave({ name: badgeName, imageUrl: badgeImageUrl });
                } else {
                  onSave({ id: data.id, name: badgeName, imageUrl: badgeImageUrl });
                }
                onClose();
              }}
            >
              저장
            </Button>
          </ModalFooter>
        </>
      </ModalContent>
    </Modal>
  );
};
