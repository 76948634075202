/* eslint-disable no-restricted-globals */
import { AdmissionApplyCountModal } from '@component/admission/AdmissionApplyCountModal';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useAdmissionApplyCounts } from '@hook/useAdmissionApplyCount';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import Text from '@simppl/component/Text';
import { Search } from 'lucide-react';

export function AdmissionApplyCountPage() {
  const {
    admissionApplyCountList,
    deleteAdmissionApplyCount,
    onSaveAdmissionApplyCountModal,
    setAdmissiomApplyCountModalData,
    admissiomApplyCountModalData,
  } = useAdmissionApplyCounts();

  const { filterValue, filteredItems, page, setPage, onSearchChange, onClear } =
    useSearchAndPagination(admissionApplyCountList, (admissionApplyCount, value) =>
      admissionApplyCount.date.includes(value),
    );

  const {
    isOpen: isAdmissionApplyModalOpen,
    onOpen: onAdmissionApplyModalOpen,
    onClose: onAdmissionApplyModalClose,
  } = useDisclosure();

  return (
    <div className='flex min-h-[80%] flex-col'>
      <Card className='grow'>
        {isAdmissionApplyModalOpen && (
          <AdmissionApplyCountModal
            isOpen={isAdmissionApplyModalOpen}
            onOpenChange={onAdmissionApplyModalOpen}
            onClose={onAdmissionApplyModalClose}
            onSave={onSaveAdmissionApplyCountModal}
            data={
              admissiomApplyCountModalData.mode === 'update'
                ? admissiomApplyCountModalData.data
                : undefined
            }
          />
        )}

        <CardHeader>
          <h1 className='text-2xl'>모의지원 지원자 수 관리</h1>
        </CardHeader>
        <Divider />
        <CardBody className='flex flex-col gap-4'>
          <Table
            layout='auto'
            topContent={
              <div className='flex flex-col gap-4'>
                <div className='flex w-full flex-row justify-between'>
                  <div className='flex max-w-sm flex-1 flex-row items-center'>
                    <Input
                      labelPlacement='outside'
                      startContent={<Search />}
                      value={filterValue}
                      onClear={() => onClear()}
                      onValueChange={onSearchChange}
                    />
                  </div>

                  <div className='flex flex-col items-center'>
                    <Text
                      as='Body'
                      size={1}
                      weight='Bold'
                      color='text-Blue-3'
                    >
                      전체 실 지원
                    </Text>
                    <Text
                      as='Label'
                      size={1}
                      weight='Medium'
                      color='text-Base-Black'
                    >
                      0명
                    </Text>
                  </div>
                  <div className='flex flex-col items-center'>
                    <Text
                      as='Body'
                      size={1}
                      weight='Bold'
                      color='text-Blue-3'
                    >
                      전체 가 지원
                    </Text>
                    <Text
                      as='Label'
                      size={1}
                      weight='Medium'
                      color='text-Base-Black'
                    >
                      0명
                    </Text>
                  </div>
                  <div className='flex flex-col items-center'>
                    <Text
                      as='Body'
                      size={1}
                      weight='Bold'
                      color='text-Blue-3'
                    >
                      전체 지원
                    </Text>
                    <Text
                      as='Label'
                      size={1}
                      weight='Medium'
                      color='text-Base-Black'
                    >
                      0명
                    </Text>
                  </div>
                  <div className='flex flex-col items-center'>
                    <Text
                      as='Body'
                      size={1}
                      weight='Bold'
                      color='text-Blue-3'
                    >
                      전체 지원 (FE)
                    </Text>
                    <Text
                      as='Label'
                      size={1}
                      weight='Medium'
                      color='text-Base-Black'
                    >
                      0명
                    </Text>
                  </div>

                  <Button
                    color='primary'
                    onPress={() => {
                      setAdmissiomApplyCountModalData({ mode: 'create' });
                      onAdmissionApplyModalOpen();
                    }}
                  >
                    전체 지원 수 관리
                  </Button>
                </div>
              </div>
            }
            topContentPlacement='outside'
          >
            <TableHeader>
              <TableColumn key='date'>날짜</TableColumn>
              <TableColumn key='applyCount'>설정 지원자 수</TableColumn>
              <TableColumn key='setting'>비고</TableColumn>
            </TableHeader>
            <TableBody items={filteredItems}>
              {(item) => (
                <TableRow
                  className='relative cursor-pointer'
                  key={item.id}
                >
                  <TableCell>{item.date.toString()}</TableCell>
                  <TableCell>{item.appliedCount}</TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          isIconOnly
                          size='sm'
                          variant='light'
                        >
                          <VerticalDotsIcon className='text-default-300' />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu>
                        <DropdownItem
                          key='edit'
                          onPress={() => {
                            setAdmissiomApplyCountModalData({
                              mode: 'update',
                              data: { date: item.date, appliedCount: item.appliedCount },
                            });
                            onAdmissionApplyModalOpen();
                          }}
                        >
                          편집
                        </DropdownItem>

                        <DropdownItem
                          key='delete'
                          className='text-red-500'
                          onPress={() => {
                            if (!confirm('정말 삭제하시겠습니까?')) return;
                            deleteAdmissionApplyCount({ id: item.id });
                          }}
                        >
                          삭제
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <div className='flex w-full flex-col items-center'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={setPage}
              total={Math.max(Math.ceil(filteredItems.length / 10), 1)}
            />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
