import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { userBadgesApi } from '@simppl/repository/user-badges';
import type {
  DeleteUserBadgesReqType,
  PostUserBadgesReqType,
} from '@simppl/repository/user-badges/req';

export const useUserBadges = ({ userId }: { userId: string }) => {
  const queryClient = useQueryClient();
  const GET_USER_BADGES_QUERY_KEY = ['get-user-badges', userId];

  const { data: badges } = useSuspenseQuery({
    queryKey: GET_USER_BADGES_QUERY_KEY,
    queryFn: () => userBadgesApi.GET({ userId }),
  });

  const invalidateGetBadges = () => {
    queryClient.invalidateQueries(GET_USER_BADGES_QUERY_KEY);
  };

  const postBadgeHook = useMutation(userBadgesApi.POST);

  const deleteBadgeHook = useMutation(userBadgesApi.DELETE);

  const postUserBadge = (body: PostUserBadgesReqType) =>
    postBadgeHook.mutate(body, { onSuccess: invalidateGetBadges });

  const deleteUserBadge = (body: DeleteUserBadgesReqType) =>
    deleteBadgeHook.mutate(body, {
      onSuccess: invalidateGetBadges,
    });

  return { badges, postUserBadge, deleteUserBadge };
};
