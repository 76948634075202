/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import Spacing from '@simppl/component/Spacing';
import Text from '@simppl/component/Text';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { admissionSeasonApi } from '@simppl/repository/admission';
import { ChevronDownIcon } from 'lucide-react';
import { useState } from 'react';

type ApplicantSeasonModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
};

enum ApplicantSeasons {
  '12특(후기)' = '12Y',
  '3특(전기)' = '3Y',
  '연세대 수시' = 'EARLY_YONSEI',
  '카이스트 수시' = 'EARLY_KAIST',
}

const getSeasonKeyByValue = (value: ApplicantSeasons) => {
  return Object.entries(ApplicantSeasons).find(([key, val]) => val === value)?.[0];
};

export function AdmissionSeasonModal(props: ApplicantSeasonModalProps) {
  const {
    data: { season },
  } = useSuspenseQuery({
    queryKey: ['application-season'],
    queryFn: () => admissionSeasonApi.get(),
  });

  const [selectedKeys, setSelectedKeys] = useState<Set<ApplicantSeasons>>(
    new Set([(season as ApplicantSeasons) || ApplicantSeasons['12특(후기)']]),
  );

  const queryClient = useQueryClient();

  const patchApplicantSeasonHook = useMutation(admissionSeasonApi.patch);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>모집 전형 관리</ModalHeader>
            <ModalBody>
              <Text
                size={1}
                as='Title'
                color='text-Blue-5'
                weight='Bold'
              >
                현재 모집전형: {getSeasonKeyByValue(season as ApplicantSeasons)}
              </Text>
              <Spacing height={24} />
              <Text
                size={2}
                as='Label'
                color='text-Blue-1'
                weight='Medium'
              >
                - 3특 / 12특 선택 시: 성적 입력 [ 고교 / 어학 / 표준화 / 활동수상] <br />
                <br />- 3특 선택 시: 12특 + 3특 동시 오픈
                <br />
                <br />- 연세대 / 카이스트 선택 시: 성적 입력 [ 고교 / 표준화 ]
              </Text>
              <Spacing height={24} />
              <Text
                size={1}
                as='Body'
                color='text-Blue-5'
                weight='Bold'
              >
                모집전형 변경
              </Text>
              <Dropdown>
                <DropdownTrigger className='hidden sm:flex'>
                  <Button endContent={<ChevronDownIcon className='text-small' />}>
                    {getSeasonKeyByValue([...selectedKeys][0]) || '선택'}
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  disallowEmptySelection
                  aria-label='Table Columns'
                  closeOnSelect
                  selectionMode='single'
                  selectedKeys={selectedKeys}
                  onSelectionChange={(keys) => {
                    const newSet = new Set(
                      Array.from(keys).map(
                        (key) => ApplicantSeasons[key as keyof typeof ApplicantSeasons],
                      ),
                    );
                    setSelectedKeys(newSet);
                  }}
                >
                  {Object.entries(ApplicantSeasons).map(([key, value]) => (
                    <DropdownItem
                      key={key}
                      className='capitalize'
                    >
                      {key}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </ModalBody>
            <Spacing height={24} />
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  patchApplicantSeasonHook.mutate(
                    { season: [...selectedKeys][0] },
                    {
                      onSuccess: () => {
                        queryClient.invalidateQueries(['application-season']);
                        props.onClose();
                      },
                    },
                  );
                }}
              >
                변경
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
