import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import LoginRequired from '../hoc/LoginRequired';
import NonLoginRequired from '../hoc/NonLoginRequired';
import { AdmissionPage } from './admission/AdmissionPage';
import { SimulatedApplicationPage } from './admission/SimulatedApplicationPage';
import { CompetitiveUnitAdmissionPage } from './admission/competitiveUnit/CompetitiveUnitAdmissionPage';
import { CompetitiveUnitPage } from './admission/competitiveUnit/CompetitiveUnitPage';
import CategoryPage from './community/CategoryPage';
import FaqPage from './community/FaqPage';
import PostPage from './community/PostPage';
import InquiryPage from './community/inquiry/InquiryPage';
import NoticeCommentPage from './community/notice/NoticeCommentPage';
import NoticePage from './community/notice/NoticePage';
import AdmissionsPage from './database/AdmissionsPage';
import BadgePage from './database/BadgePage';
import CountryPage from './database/CountryPage';
import HighSchoolPage from './database/HighSchoolPage';
import ItemPage from './database/ItemPage';
import UniversityPage from './database/UniversityPage';
import UserPaymentPage from './database/UserPaymentPage';
import { AdmissionApplyCountPage } from './mock/AdmissionApplyCountPage';
import FakeApplicantPage from './mock/FakeApplicantPage';
import App from './root/App';
import { HighSchoolSubjectPage } from './subject/HighSchoolSubjectPage';
import { StandarizedSubjectPage } from './subject/StandarizedSubjectPage';
import UserDetailPage from './user/UserDetailPage';

const ApplicantPage = lazy(() => import('./mock/ApplicantPage'));
const LoginPage = lazy(() => import('./login/LoginPage'));
const Mainpage = lazy(() => import('./root/Mainpage'));
const SettingPage = lazy(() => import('./system/SettingPage'));
const UserPage = lazy(() => import('./user/UserPage'));
const AppPushAlarmPage = lazy(() => import('./appPush/AppPushPage'));

export const router: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    errorElement: <App.Error />,
    children: [
      {
        path: '/',
        element: <Outlet />,
        children: [
          {
            path: '/',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <Mainpage />
                </Suspense>
              </LoginRequired>
            ),
          },
        ],
      },
      {
        path: '/user',
        element: <Outlet />,
        children: [
          {
            path: '/user',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <UserPage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/user/:id',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <UserDetailPage />
                </Suspense>
              </LoginRequired>
            ),
          },
        ],
      },
      {
        path: '/admission',
        element: <Outlet />,
        children: [
          {
            path: '/admission/simulatedApplication',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <SimulatedApplicationPage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/admission/admission',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <AdmissionPage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/admission/competitiveUnit',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <CompetitiveUnitPage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/admission/competitiveUnit/:competitiveUnitId',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <CompetitiveUnitAdmissionPage />
                </Suspense>
              </LoginRequired>
            ),
          },
        ],
      },
      {
        path: '/app-push',
        element: (
          <LoginRequired>
            <Suspense fallback={<>...loading</>}>
              <AppPushAlarmPage />
            </Suspense>
          </LoginRequired>
        ),
      },
      {
        path: '/community',
        element: <Outlet />,
        children: [
          {
            path: '/community/notice',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <NoticePage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/community/notice/:id',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <NoticeCommentPage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/community/faq',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <FaqPage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/community/category',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <CategoryPage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/community/inquiry',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <InquiryPage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/community/post',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <PostPage />
                </Suspense>
              </LoginRequired>
            ),
          },
        ],
      },
      {
        path: '/mock',
        element: <Outlet />,
        children: [
          {
            path: '/mock/fakeApplicant',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <FakeApplicantPage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/mock/applicant',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <ApplicantPage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/mock/apply-count',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <AdmissionApplyCountPage />
                </Suspense>
              </LoginRequired>
            ),
          },
        ],
      },
      {
        path: '/database',
        element: <Outlet />,
        children: [
          {
            path: '/database/userPayment',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <UserPaymentPage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/database/item',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <ItemPage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/database/university',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <UniversityPage />
                </Suspense>
              </LoginRequired>
            ),
            children: [
              {
                path: '/database/university/:universityId',
                element: (
                  <LoginRequired>
                    <Suspense fallback={<>...loading</>}>
                      <UserDetailPage />
                    </Suspense>
                  </LoginRequired>
                ),
              },
            ],
          },
          {
            path: '/database/highschool',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <HighSchoolPage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/database/country',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <CountryPage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/database/badge',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <BadgePage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/database/admissionUserCount',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <AdmissionsPage />
                </Suspense>
              </LoginRequired>
            ),
          },
        ],
      },
      {
        path: '/subject',
        element: <Outlet />,
        children: [
          {
            path: '/subject/standardizedSubject',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <StandarizedSubjectPage />
                </Suspense>
              </LoginRequired>
            ),
          },
          {
            path: '/subject/highSchoolSubject',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <HighSchoolSubjectPage />
                </Suspense>
              </LoginRequired>
            ),
          },
        ],
      },
      {
        path: '/system',
        element: <Outlet />,
        children: [
          {
            path: '/system/setting',
            element: (
              <LoginRequired>
                <Suspense fallback={<>...loading</>}>
                  <SettingPage />
                </Suspense>
              </LoginRequired>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: (
      <Suspense fallback={<>...loading</>}>
        <LoginPage />
      </Suspense>
    ),
  },
];
