/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { useUniversities } from '@hook/useUniversities';
import type { CalendarDate } from '@internationalized/date';
import { parseDate } from '@internationalized/date';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  DatePicker,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
  Switch,
} from '@nextui-org/react';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { simulatedApplications } from '@simppl/repository/simulatedApplications';
import c from '@simppl/util/c';
import { extractDateFromTimestamp } from '@simppl/util/date';
import { generateStorage } from '@simppl/util/storage';
import { useEffect, useState } from 'react';

type SimulatedApplicationModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
  mode: 'add';
};

const substractOneSecond = (calendarDate: CalendarDate) => {
  const date = calendarDate.add({ days: 1 }).toDate('Asia/Seoul');
  date.setSeconds(date.getSeconds() - 1);
  return date.toISOString();
};

export function SimulatedApplicationAddModal(props: SimulatedApplicationModalProps) {
  const storage = generateStorage();
  const { data } = useUniversities({
    // isMain null 전체 대학 조회
    name: '',
    page: 1,
    size: 100000,
  });

  const [startDate, setStartDate] = useState(
    parseDate(extractDateFromTimestamp(new Date().toISOString())),
  );
  const [endDate, setEndDate] = useState(
    parseDate(extractDateFromTimestamp(new Date().toISOString())),
  );
  const [sort, setSort] = useState<'3Y' | '12Y' | 'EARLY'>('3Y');
  const [examSort, setExamSort] = useState<'exam' | 'document'>('exam');
  const [admissionAutoCreate, setAdmissionAutoCreate] = useState(false);
  const [universityListObj, setUniversityListObj] = useState<
    Record<string, { id: string; name: string }>
  >({});

  const queryClient = useQueryClient();

  const postUniversitiesHook = useMutation(simulatedApplications.post);

  useEffect(() => {
    const simulatedApplicationDate = JSON.parse(storage.get('simulatedApplicationDate') ?? '{}');

    if ('startDate' in simulatedApplicationDate && 'endDate' in simulatedApplicationDate) {
      setStartDate(parseDate(extractDateFromTimestamp(simulatedApplicationDate.startDate)));
      setEndDate(parseDate(extractDateFromTimestamp(simulatedApplicationDate.endDate)));
    }
  }, [props, storage]);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>전형 추가</ModalHeader>
            <ModalBody>
              <Autocomplete
                label='대학을 선택해주세요.'
                className='max-w-xs'
                onSelectionChange={(value) => {
                  if (typeof value !== 'string') return;
                  if (value in universityListObj) {
                    setUniversityListObj((prev) => {
                      const newObj = { ...prev };
                      // eslint-disable-next-line no-param-reassign
                      delete newObj[value];
                      return newObj;
                    });
                  } else {
                    const univ = data.find((univ) => univ.universityId === value);
                    if (!univ) return;
                    setUniversityListObj((prev) => ({
                      ...prev,
                      [value]: {
                        id: value,
                        name: univ.name,
                      },
                    }));
                  }
                }}
              >
                {data.map((university) => (
                  <AutocompleteItem key={university.universityId}>
                    <div className='flex justify-between'>
                      <span>{university.name}</span>
                      {university.universityId in universityListObj && <span>✅</span>}
                    </div>
                  </AutocompleteItem>
                ))}
              </Autocomplete>
              <DatePicker
                label='시작일'
                labelPlacement='outside'
                value={startDate as any}
                onChange={setStartDate as any}
              />
              <DatePicker
                label='마감일'
                labelPlacement='outside'
                value={endDate as any}
                onChange={setEndDate as any}
              />
              <div className='flex w-full space-x-[20px]'>
                <RadioGroup
                  label='전형 구분'
                  value={sort}
                  onValueChange={setSort as any}
                >
                  <Radio value='3Y'>3Y</Radio>
                  <Radio value='12Y'>12Y</Radio>
                </RadioGroup>
                <RadioGroup
                  label='시험 구분'
                  value={examSort}
                  onValueChange={setExamSort as any}
                >
                  <Radio value='exam'>집필</Radio>
                  <Radio value='document'>서류</Radio>
                </RadioGroup>
                <span>모집단위 일괄 생성</span>
                <Switch
                  checked={admissionAutoCreate}
                  onChange={(e) => setAdmissionAutoCreate(e.target.checked)}
                />
              </div>
              {Object.keys(universityListObj).length > 0 && (
                <ul
                  className={c(
                    'border-2 p-[12px]',
                    'flex flex-col space-y-[8px]',
                    'max-h-[200px] overflow-y-auto',
                    'rounded-[16px]',
                  )}
                >
                  {Object.values(universityListObj).map(({ id, name }) => (
                    <li
                      key={id}
                      className='flex justify-between'
                    >
                      <span>{name}</span>
                      <button
                        onClick={() =>
                          setUniversityListObj((prev) => {
                            const newObj = { ...prev };
                            delete newObj[id];
                            return newObj;
                          })
                        }
                      >
                        ❌
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={async () => {
                  postUniversitiesHook.mutate(
                    {
                      startDate: startDate.toString(),
                      endDate: substractOneSecond(endDate),
                      sort,
                      universityId: Object.keys(universityListObj),
                      examSort,
                      admissionAutoCreate,
                    },
                    {
                      onSuccess: () => {
                        queryClient.invalidateQueries(['getSimulatedApplications']);
                        queryClient.invalidateQueries(['getAdmissions']);
                        props.onClose();
                      },
                    },
                  );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
