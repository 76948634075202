import axios from '..';
import type {
  DeleteUserBadgesReqType,
  GetUserBadgesReqType,
  PatchRepresentativeUserBadgesReqType,
  PostUserBadgesReqType,
} from './req';
import type {
  DeleteUserBadgesResType,
  GetRepresentativeUserBadgeResType,
  GetUserBadgesResType,
  PatchRepresentativeUserBadgesResType,
  PostUserBadgesResType,
} from './res';

export const userBadgesApi = {
  GET: async (body: GetUserBadgesReqType) =>
    (await axios.get<GetUserBadgesResType>(`/user-badges/${body.userId}`)).data,
  POST: async (body: PostUserBadgesReqType) =>
    (await axios.post<PostUserBadgesResType>(`/user-badges/${body.userId}`, body)).data,
  DELETE: async (body: DeleteUserBadgesReqType) =>
    (await axios.delete<DeleteUserBadgesResType>(`/user-badges/${body.id}`)).data,
  REPRESENTATIVE: {
    GET: async () =>
      (await axios.get<GetRepresentativeUserBadgeResType>(`/user-badges/representative`)).data,
    PATCH: async (body: PatchRepresentativeUserBadgesReqType) =>
      (await axios.patch<PatchRepresentativeUserBadgesResType>(`/user-badges/representative`, body))
        .data,
  },
};
