import type { PostMockUserAdmissionsReqType } from '@simppl/repository/admins/req';
import { useReducer } from 'react';

type State = {
  id: string; // 배열 요소를 구분하기 위한 고유 id (uuid 같은 거 추천)
  type: InterestType | null;
  universityId: string | null;
  universityName: string | null;
  admissionId: string | null;
  admissionName: string | null;
  score: Pick<
    PostMockUserAdmissionsReqType['admissionInfo'][number],
    'maxScore' | 'minScore' | 'maxSerializedScore' | 'minSerializedScore' | 'peopleNum'
  > | null;
};

type Action =
  | { type: 'ADD_ITEM'; payload: State }
  | { type: 'REMOVE_ITEM'; payload: string } // id 기준으로 삭제
  | { type: 'SELECT_INTEREST_TYPE'; id: string; payload: InterestType }
  | { type: 'CANCEL_INTEREST_TYPE'; id: string }
  | {
      type: 'SELECT_UNIVERSITY_ID';
      id: string;
      payload: {
        id: string;
        name: string;
      };
    }
  | { type: 'CANCEL_UNIVERSITY_ID'; id: string }
  | {
      type: 'SELECT_ADMISSION_ID';
      id: string;
      payload: {
        id: string;
        name: string;
      };
    }
  | { type: 'CANCEL_ADMISSION_ID'; id: string }
  | { type: 'WRITE_SCORE'; id: string; payload: State['score'] }
  | { type: 'RESET' };

const initialItem = (): State => ({
  id: crypto.randomUUID(), // 각 아이템을 유니크하게 구분 (브라우저 지원 가능)
  type: null,
  universityId: null,
  universityName: null,
  admissionId: null,
  admissionName: null,
  score: null,
});

const reducer = (state: State[], action: Action): State[] => {
  switch (action.type) {
    case 'ADD_ITEM':
      return [...state, action.payload];

    case 'REMOVE_ITEM':
      return state.filter((item) => item.id !== action.payload);

    case 'SELECT_INTEREST_TYPE':
      return state.map((item) =>
        item.id === action.id ? { ...item, type: action.payload } : item,
      );

    case 'CANCEL_INTEREST_TYPE':
      return state.map((item) => (item.id === action.id ? { ...item, type: null } : item));

    case 'SELECT_UNIVERSITY_ID':
      return state.map((item) =>
        item.id === action.id
          ? { ...item, universityId: action.payload.id, universityName: action.payload.name }
          : item,
      );

    case 'CANCEL_UNIVERSITY_ID':
      return state.map((item) => (item.id === action.id ? { ...item, universityId: null } : item));

    case 'SELECT_ADMISSION_ID':
      return state.map((item) =>
        item.id === action.id
          ? { ...item, admissionId: action.payload.id, admissionName: action.payload.name }
          : item,
      );

    case 'CANCEL_ADMISSION_ID':
      return state.map((item) => (item.id === action.id ? { ...item, admissionId: null } : item));

    case 'WRITE_SCORE':
      return state.map((item) =>
        item.id === action.id ? { ...item, score: action.payload } : item,
      );

    case 'RESET':
      return [];

    default:
      return state;
  }
};

// 커스텀 훅
export const useFakeAdmissionUserAdd = () => {
  const [state, dispatch] = useReducer(reducer, []);

  // 아이템 추가할 때 편하게 쓰는 헬퍼 함수
  const addItem = () => {
    const newItem = initialItem();
    dispatch({ type: 'ADD_ITEM', payload: newItem });
    return newItem.id;
  };

  const removeItem = (id: string) => {
    dispatch({ type: 'REMOVE_ITEM', payload: id });
  };

  return {
    state,
    dispatch,
    addItem,
    removeItem,
  };
};
