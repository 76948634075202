import { useAdmissionsUniversities } from '@hook/useAdmissionsUniversity';
import { Pagination } from '@nextui-org/react';
import Text from '@simppl/component/Text';
import c from '@simppl/util/c';
import { Search } from 'lucide-react';
import { useEffect, useState } from 'react';

type SelectUniversityProps = {
  universityId: string | null;
  onClick: (props: { id: string; name: string }) => void;
  goNext: () => void;
};

function SelectUniversity({ universityId, onClick, goNext }: SelectUniversityProps) {
  const [input, setInput] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const { data } = useAdmissionsUniversities({
    // isMain null 전체 대학 조회
    name: input,
    page,
    size: 12,
  });

  const totalPageOffset = data?.page;
  useEffect(() => {
    setPage(1);
  }, [input]);

  return (
    <div className='flex flex-col gap-[40px] text-center'>
      <Text
        as='Headline'
        size={2}
        weight={700}
        color='text-blue-500'
      >
        학교 선택
      </Text>
      <div className='border-Blue-Ct-2 flex h-[374px] w-[630px] flex-col gap-[32px] rounded-[16px] border-[0.5px] p-[32px] shadow-[0px_1px_2px_0px_rgba(20,_25,_97,_0.10),_2px_2px_3px_0px_rgba(20,_25,_97,_0.09),_4px_5px_4px_0px_rgba(20,_25,_97,_0.05),_8px_9px_5px_0px_rgba(20,_25,_97,_0.01),_12px_14px_5px_0px_rgba(20,_25,_97,_0.00)]'>
        <div className='relative'>
          <input
            className='h-[40px] w-[556px] rounded-[4px] border-[0.5px] border-blue-700 pl-[56px] pr-[180px] text-blue-500'
            placeholder='학교 검색'
            onInput={(e) => {
              setInput(e.currentTarget.value);
            }}
          />
          <div className='absolute left-[16px] top-[8px] flex h-[24px] w-[24px] items-center justify-center'>
            <Search />
          </div>
        </div>
        <div className='flex w-full flex-col gap-[32px]'>
          <Text
            as='Body'
            size={1}
            weight='Regular'
            color='text-blue-600'
          >
            {input && input.length > 0 ? '검색된 학교' : '주요 학교'}
          </Text>
          <div className='flex h-[108px] w-full flex-wrap'>
            {data?.universities?.map(({ universityId: _universityId, name }) => (
              <div
                key={_universityId}
                className='h-[28px] w-[25%]'
              >
                <div
                  className={c(
                    'group flex h-[28px] w-[128px] items-center justify-center gap-[16px]',
                    universityId === _universityId && 'rounded-[8px] bg-blue-500',
                  )}
                  onClick={() => onClick({ id: _universityId, name })}
                  onDoubleClick={goNext}
                >
                  <Text
                    className={c(
                      'w-[120px] cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap',
                      universityId === _universityId ? 'text-white' : 'group-hover:text-blue-500',
                    )}
                    as='Title'
                    size={1}
                    weight='Regular'
                    color='text-black'
                  >
                    {name}
                  </Text>
                </div>
              </div>
            ))}
          </div>
          <div className='m-auto'>
            <Pagination
              isCompact
              showControls
              showShadow
              page={page}
              onChange={(page) => setPage(page)}
              total={Math.max(totalPageOffset ?? 1, 1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectUniversity;
