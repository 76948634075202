/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import type {
  PatchAdmissionApplyCountReqType,
  PostAdmissionApplyCountReqType,
} from '@simppl/repository/admission/req';
import { useState } from 'react';

type AdmissionApplyCountModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
  onSave: (data: PostAdmissionApplyCountReqType | PatchAdmissionApplyCountReqType) => void;
  id?: number;
  data?: PatchAdmissionApplyCountReqType;
};

export function AdmissionApplyCountModal(props: AdmissionApplyCountModalProps) {
  const [date, setDate] = useState<string>(props.data?.date || '');
  const [appliedCount, setAppliedCount] = useState<number>(props.data?.appliedCount || 0);

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>모집단위 추가</ModalHeader>
            <ModalBody>
              <Input
                type='date'
                value={date as any} // next ui 타입때문에 어쩔수
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                label='날짜'
                labelPlacement='outside'
              />
              <Input
                type='number'
                value={appliedCount as any} // next ui 타입때문에 어쩔수
                onChange={(e) => setAppliedCount(parseInt(e.target.value, 10))}
                label='지원인원'
                labelPlacement='outside'
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  props.onSave({ date, appliedCount });
                  props.onClose();
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
