import { Checkbox } from '@nextui-org/react';
import Text from '@simppl/component/Text';

type SelectTypeProps = {
  type: InterestType | null;
  onClick: (type: InterestType) => void;
};

function SelectType({ type, onClick }: SelectTypeProps) {
  return (
    <div className='flex flex-col gap-[20px] text-center'>
      <Text
        as='Headline'
        size={2}
        weight={700}
        color='text-blue-500'
      >
        전형 선택
      </Text>
      <div className='flex justify-evenly px-10'>
        {TYPE.map(({ name, value, disabled }) => (
          <div
            key={value}
            className='flex'
          >
            <Checkbox
              isSelected={type === value}
              onValueChange={() => onClick(value)}
              disabled={disabled}
            />
            <Text
              as='Title'
              size={1}
              weight='Regular'
              color='text-Blue-5'
            >
              {name}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SelectType;

const TYPE = [
  {
    name: '3년 특례',
    value: '3Y',
    disabled: true,
  },
  {
    name: '12년 특례',
    value: '12Y',
    disabled: false,
  },
  {
    name: '수시',
    value: 'EARLY',
    disabled: false,
  },
] as const;
