/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import Spacing from '@simppl/component/Spacing';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { mockUserAdmissions } from '@simppl/repository/admins';
import c from '@simppl/util/c';
import { useEffect, useState } from 'react';

import AddSubjectData from './components/AddSubjectData';
import SelectSubject from './components/SelectSubject';
import SelectType from './components/SelectType';
import SelectUniversity from './components/SelectUniversity';
import { useFakeAdmissionUserAdd } from './hook';

type FakeApplicantAddModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
};

export const FakeApplicantAddModal = ({
  isOpen,
  onOpenChange,
  onClose,
}: FakeApplicantAddModalProps) => {
  const queryClient = useQueryClient();
  const { state, dispatch, addItem, removeItem } = useFakeAdmissionUserAdd();
  const [step, setStep] = useState(0);
  const [selectId, setSelectId] = useState<string | null>(
    () => state.find((item) => item.type === null)?.id ?? null,
  );
  const target = state.find((item) => item.id === selectId);
  useEffect(() => {
    const targetState = state.find((item) => item.id === selectId);
    if (!targetState) {
      setStep(0);
    } else {
      setStep(() => {
        if (targetState.type === null) return 0;
        if (targetState.universityId === null) return 1;
        if (targetState.admissionId === null) return 2;
        return 3;
      });
    }
  }, [selectId, state]);

  const goNext = () => setStep(step + 1);

  const goBack = (id: string) => {
    switch (step) {
      case 0:
        return;
      case 1: {
        setStep(0);
        dispatch({ type: 'CANCEL_INTEREST_TYPE', id });
        return;
      }
      case 2: {
        setStep(1);
        dispatch({ type: 'CANCEL_UNIVERSITY_ID', id });
        return;
      }
      case 3: {
        setStep(2);
        dispatch({ type: 'CANCEL_ADMISSION_ID', id });
      }
    }
  };

  const handleClose = () => {
    setStep(0);
    dispatch({ type: 'RESET' });
    onClose();
  };

  return (
    <Modal
      size='5xl'
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onClose={handleClose}
    >
      <ModalContent>
        <ModalHeader>가 데이터 추가</ModalHeader>
        <ul
          className={c(
            'w-full',
            'max-h-[300px] min-h-[150px] overflow-y-auto',
            'rounded-4 border-[1px] border-slate-400',
            'flex flex-wrap items-start justify-start gap-x-2',
          )}
        >
          {state.map(({ id, admissionName, universityName }) => (
            <li
              key={id}
              className={c(
                'flex items-center gap-x-4 py-2',
                target?.id === id && universityName
                  ? 'rounded-[12px] border-[1px] border-green-500 px-[4px]'
                  : '',
              )}
              onClick={() => setSelectId(id)}
            >
              <span>{[universityName, admissionName].filter(Boolean).join('-')}</span>
              {universityName && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    removeItem(id);
                  }}
                >
                  ❌
                </button>
              )}
            </li>
          ))}
        </ul>
        <Button
          type='button'
          className='ml-[20px] mt-[20px] w-fit'
          form='authorization'
          color='primary'
          onClick={() => {
            state
              .filter((item) => !item.admissionId || !item.universityId)
              .map(({ id }) => removeItem(id));
            const newId = addItem();
            setSelectId(newId);
          }}
        >
          모집 단위 선택하기
        </Button>
        <ModalBody className='flex !flex-row'>
          <div className='flex-1'>
            {step === 0 && selectId && target && (
              <SelectType
                type={target.type}
                onClick={(type) => {
                  dispatch({ type: 'SELECT_INTEREST_TYPE', id: selectId, payload: type });
                }}
              />
            )}
            {step === 1 && selectId && target && target.type && (
              <SelectUniversity
                universityId={target.universityId}
                onClick={({ id, name }) => {
                  dispatch({ type: 'SELECT_UNIVERSITY_ID', payload: { id, name }, id: selectId });
                }}
                goNext={goNext}
              />
            )}
            {step === 2 && selectId && target && target.type && target.universityId && (
              <SelectSubject
                type={target.type}
                universityId={target.universityId}
                selectAdmissionId={target.admissionId}
                onClick={({ id, name }) => {
                  dispatch({ type: 'SELECT_ADMISSION_ID', payload: { id, name }, id: selectId });
                }}
                goNext={goNext}
              />
            )}
            {[1, 2].includes(step) && (
              <>
                <Spacing height='16px' />
                <div className='flex justify-end'>
                  <div className='flex gap-x-2'>
                    <Button
                      type='button'
                      form='authorization'
                      color='default'
                      onClick={() => goBack(selectId!)}
                      disabled={selectId === null}
                    >
                      전으로
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className='flex-1'>
            {target && target.admissionId && (
              <AddSubjectData
                key={selectId}
                targetName={[target.universityName, target.admissionName].join('-')}
                score={target.score!}
                onChange={(score) =>
                  dispatch({ type: 'WRITE_SCORE', id: selectId!, payload: score })
                }
              />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type='button'
            color='danger'
            variant='light'
            onPress={handleClose}
          >
            닫기
          </Button>
          {step === 3 && (
            <Button
              type='button'
              color='primary'
              disabled={
                state.filter((stateItem) => !!stateItem.score && !!stateItem.admissionId).length ===
                0
              }
              onClick={() => {
                mockUserAdmissions
                  .post({
                    admissionInfo: state
                      .filter((stateItem) => !!stateItem.score && !!stateItem.admissionId)
                      .map((stateItem) => ({
                        admissionId: stateItem.admissionId!,
                        peopleNum: stateItem.score!.peopleNum,
                        minScore: stateItem.score!.minScore,
                        maxScore: stateItem.score!.maxScore,
                        minSerializedScore: stateItem.score!.minSerializedScore,
                        maxSerializedScore: stateItem.score!.maxSerializedScore,
                      })),
                  })
                  .then(() => {
                    queryClient.invalidateQueries(['useMockUserAdmissions']);
                    handleClose();
                  })
                  .catch(() => {
                    alert('저장중에 오류가 발생했습니다. 다시 시도해주세요.');
                  });
              }}
            >
              추가하기
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
