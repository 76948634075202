/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable react/destructuring-assignment */
import { useNoticeId } from '@hook/useNoticeId';
import {
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import Editor from '@simppl/component/Editor';
import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import { notice } from '@simppl/repository/notice';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

type NoticeModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
} & (
  | {
      mode: 'add';
    }
  | { mode: 'edit'; id: string }
);

export function NoticeModal(props: NoticeModalProps) {
  const [content, setContent] = useState('' as string);
  const [isFixed, setIsFixed] = useState(false);
  const item = props.mode === 'edit' ? useNoticeId({ id: parseInt(props.id, 10) }).data : null;
  if (props.mode === 'edit' && !item) return null;

  const queryClient = useQueryClient();

  const postItemHook = useMutation(notice.post);
  const patchItemHook = useMutation(notice.patch);

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
  });
  const { control, handleSubmit } = methods;

  const [
    title,
    // content,
    // isFixed,
    viewCount,
  ] = [
    'title',
    // 'content',
    // 'isFixed',
    'viewCount',
  ].map((t) => methods.watch(t as any));

  useEffect(() => {
    if (props.mode === 'edit' && item) {
      methods.setValue('title', item.title);
      setIsFixed(item.isFixed);
      setContent(item.content);
      methods.setValue('viewCount', item.viewCount);
    }
  }, [props, item]);

  return (
    <Modal
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='flex flex-col gap-1'>
              {props.mode === 'add' ? '공지사항 추가' : '공지사항 수정'}
            </ModalHeader>
            <ModalBody>
              <Controller
                name='title'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <Input
                    labelPlacement='outside'
                    placeholder='제목'
                    {...field}
                  />
                )}
              />
              <Editor
                initialData={item?.content || ''}
                onChange={setContent}
              />
              <Checkbox
                isSelected={isFixed}
                onValueChange={setIsFixed}
              >
                필독 여부
              </Checkbox>
              <Controller
                name='viewCount'
                control={control}
                defaultValue='1'
                render={({ field }) => (
                  <Input
                    type='number'
                    labelPlacement='outside'
                    placeholder='조회수'
                    {...field}
                  />
                )}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={props.onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  if (props.mode === 'add')
                    postItemHook.mutate(
                      {
                        title,
                        content,
                        isFixed,
                        viewCount: parseInt(viewCount, 10),
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['getNotices']);
                          props.onClose();
                        },
                      },
                    );
                  else if (props.mode === 'edit' && item?.id)
                    patchItemHook.mutate(
                      {
                        id: item.id.toString(),
                        title,
                        content,
                        isFixed,
                        viewCount: parseInt(viewCount, 10),
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries(['getNotices']);
                          props.onClose();
                        },
                      },
                    );
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
