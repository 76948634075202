/* eslint-disable no-shadow */
// import { UploadFile } from '@component/ScoreFileUploader';
import { VerticalDotsIcon } from '@component/icon/VerticalDotsIcon';
import { useBadges } from '@hook/useBadges';
import { useSearchAndPagination } from '@hook/useSearchAndPagination';
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Checkbox,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Textarea,
} from '@nextui-org/react';
import Text from '@simppl/component/Text';
import { items } from '@simppl/repository/items';
import type { PostUserBadgesReqType } from '@simppl/repository/user-badges/req';
import type { GetUserBadgesResType } from '@simppl/repository/user-badges/res';
import { Search } from 'lucide-react';
import { useState } from 'react';

type UserBadgeAddModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
  onSave: (data: PostUserBadgesReqType) => void;
  id?: string;
  userBadges: GetUserBadgesResType['data'];
  userId: string;
};

export const UserBadgesAddModal = ({
  isOpen,
  onOpenChange,
  onClose,
  onSave,
  userBadges,
  userId,
}: UserBadgeAddModalProps) => {
  const { badges } = useBadges();
  const [selectedBadgeIds, setSelectedBadgeIds] = useState<Set<string>>(new Set());

  const onCheckBoxChanged = (badgeId: string) => {
    setSelectedBadgeIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(badgeId)) {
        newSet.delete(badgeId);
      } else {
        newSet.add(badgeId);
      }
      return newSet;
    });
  };

  const userBadgeIds = userBadges.map(({ badgeId }) => badgeId);
  const addAvailabledBadges = badges.filter(({ id }) => !userBadgeIds.includes(id));

  const { filterValue, filteredItems, items, page, setPage, onSearchChange, onClear } =
    useSearchAndPagination(addAvailabledBadges, (badge, SearchValue) =>
      badge.name.includes(SearchValue),
    );

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalContent>
        {() => (
          <div>
            <ModalHeader className='flex flex-col gap-1' />
            <ModalBody>
              <Table
                layout='auto'
                topContent={
                  <div className='flex flex-col gap-4'>
                    <Text
                      size={1}
                      as='Title'
                      color='text-Blue-5'
                      weight='Bold'
                    >
                      추가 가능한 뱃지 목록
                    </Text>
                    <div className='flex w-full flex-row justify-between'>
                      <div className='flex max-w-sm flex-1 flex-row items-center'>
                        <Input
                          labelPlacement='outside'
                          startContent={<Search />}
                          value={filterValue}
                          onClear={() => onClear()}
                          onValueChange={onSearchChange}
                        />
                      </div>
                    </div>
                  </div>
                }
                topContentPlacement='outside'
              >
                <TableHeader>
                  <TableColumn key='check'>선택</TableColumn>
                  <TableColumn key='name'>뱃지 이름</TableColumn>
                  <TableColumn key='image'>뱃지 이미지</TableColumn>
                </TableHeader>
                <TableBody items={items}>
                  {(badge) => (
                    <TableRow
                      className='relative cursor-pointer'
                      key={badge.id}
                    >
                      <TableCell>
                        <Checkbox onValueChange={() => onCheckBoxChanged(badge.id)} />
                      </TableCell>
                      <TableCell>{badge.name}</TableCell>
                      <TableCell>
                        <Image
                          src={badge.imageUrl}
                          alt={badge.name}
                          width={50}
                          height={50}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <div className='flex w-full flex-col items-center'>
                <Pagination
                  isCompact
                  showControls
                  showShadow
                  page={page}
                  onChange={setPage}
                  total={Math.max(Math.ceil(filteredItems.length / 10), 1)}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color='danger'
                variant='light'
                onPress={onClose}
              >
                취소
              </Button>
              <Button
                type='submit'
                form='authorization'
                color='primary'
                onPress={() => {
                  onSave({ badgeIds: [...selectedBadgeIds], userId });
                  onClose();
                }}
              >
                저장
              </Button>
            </ModalFooter>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};
