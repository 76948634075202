/* eslint-disable no-shadow */
import useMutation from '@simppl/react-query/useMutation';
import { postFilesApi } from '@simppl/repository/files';
import type { PostScoreFilesReqType } from '@simppl/repository/files/req';

const FileUploader = ({
  content = '파일 첨부하기',
  placeholder = '파일 첨부',
  sizeLimit = 8 * 1024 * 1024,
  accept = 'image/png, image/svg+xml, image/jpeg, image/jpg, application/pdf',
  repository = 'common',
  onClose,
  onFilePostSuccess,
  setFileUrl,
  setFileName,
  setLocalFileUrl,
}: {
  content?: string;
  placeholder?: string;
  sizeLimit?: number;
  accept?: string;
  repository?: string;
  setFileUrl: (url: string) => void;
  setFileName?: (name: string) => void;
  setLocalFileUrl?: (url: string) => void;
  onFilePostSuccess?: () => void;
  onClose?: () => void;
}) => {
  const postFilesHook = useMutation((props: PostScoreFilesReqType) => postFilesApi.POST(props));

  return (
    <>
      <label
        htmlFor='fileUploader'
        className='bg-default-100 text-small m-auto flex h-[40px] w-full items-center overflow-hidden whitespace-nowrap rounded-[12px] px-3'
      >
        <p>{content || '파일 첨부하기'}</p>
      </label>
      <input
        type='file'
        id='fileUploader'
        accept={accept}
        multiple={false}
        placeholder={placeholder}
        className='hidden'
        onClick={() => {
          onClose?.();
        }}
        onChange={(e) => {
          if (!e.target.files || e.target.files?.length === 0) {
            onClose?.();
            return;
          }

          const file = e.target.files[0];
          if (file.size <= 0) {
            alert('파일이 업로드되지 않았습니다. 고객센터로 문의부탁드립니다.');
            return;
          }

          if (file.size > sizeLimit) {
            alert('8MB 이하의 파일만 첨부 가능합니다.');
            return;
          }

          setFileName?.(file.name);
          setLocalFileUrl?.(URL.createObjectURL(file));

          const formData = new FormData();
          formData.append('file', file);
          formData.append('fileName', file.name);
          formData.append('repository', repository);

          postFilesHook.mutate(formData, {
            onSuccess: ({ data }) => {
              setFileUrl(data.fileUrl);
              onFilePostSuccess?.();
              onClose?.();
            },
            onError: () => {
              alert('파일이 업로드되지 않았습니다.');
            },
          });
        }}
      />
    </>
  );
};

export default FileUploader;
