import { ErrorMessage } from '@hookform/error-message';
import Text from '@simppl/component/Text';
import getImgUrl from '@simppl/util/getImgUrl';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { ScoreFileUploader } from './ScoreFileUploader';
import { UploaderPopUp } from './UploaderPopUp';

export function UploaderInput({
  name,
  testName,
  testType,
  alterText,
}: {
  name: string;
  testName: string;
  testType: string;
  alterText?: string;
}) {
  const [open, setOpen] = useState(false);
  const methods = useFormContext();
  return (
    <div className='relative'>
      <div
        className=' flex h-[40px] w-[155px] cursor-pointer items-center justify-between overflow-hidden whitespace-nowrap rounded-[4px] border-[1px] border-blue-600 px-[8px]'
        onClick={() => setOpen(true)}
      >
        {methods.watch(`${name}.transcript`) ? (
          <Text
            color='text-blue-500'
            weight='Regular'
            as='Label'
            size={1}
          >
            {methods.watch(`${name}.transcript`)?.length > 0
              ? decodeURIComponent(methods.watch(`${name}.transcript`).split('/').at(-1))
              : alterText}
          </Text>
        ) : (
          <Text
            color='text-blue-500'
            weight='Regular'
            as='Label'
            size={1}
          >
            파일 첨부
          </Text>
        )}
        <img
          src={getImgUrl('/asset/Button/Upload.svg')}
          alt='upload'
        />
      </div>
      <UploaderPopUp
        name={name}
        open={open}
        onClose={() => setOpen(false)}
      />
      <ScoreFileUploader
        name={name}
        testName={testName}
        testType={testType}
        onClose={() => setOpen(false)}
      />
      <ErrorMessage
        errors={methods.formState.errors}
        name={`${name}.transcript`}
        render={({ message }) => (
          <p className='absolute bottom-[-20px] text-[12px] text-red-600'>{message}</p>
        )}
      />
    </div>
  );
}
