import { SimulatedApplicationAddModal } from './Add';
import { SimulatedApplicationEditModal } from './Edit';

type SimulatedApplicationModalProps = {
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  onClose: () => void;
} & (
  | {
      mode: 'add';
    }
  | { mode: 'edit'; simulatedApplicationId: string }
);

export function SimulatedApplicationModal(props: SimulatedApplicationModalProps) {
  switch (props.mode) {
    case 'add':
      return <SimulatedApplicationAddModal {...props} />;
    case 'edit':
      return <SimulatedApplicationEditModal {...props} />;
  }
}
