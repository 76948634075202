/* eslint-disable consistent-return */

/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable no-param-reassign */

/* eslint-disable no-shadow */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import useMutation from '@simppl/react-query/useMutation';
import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { postFilesApi } from '@simppl/repository/files';
import type { PostFileReqType } from '@simppl/repository/files/req';
import { getUserInfo } from '@simppl/repository/user/index';
import { getIsMobileWebview } from '@simppl/util/isWebview';
import type { ClassicEditor, FileLoader, UploadResponse } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import { useCallback, useEffect, useState } from 'react';
import { isBrowser } from 'react-device-detect';

import {
  disableHTMLPasteOnMobile,
  overrideDropdownPositionsToNorth,
  overrideGroupDropDownPositionsToNorth,
} from './Editor.util';
import CustomCKEditor from './EditorConfig';
import './editor.css';

type EditorProps = {
  editor?: ClassicEditor | null;
  setEditor?: (editor: ClassicEditor | null) => void;
  initialData: string;
  onChange: (data: string) => void;
  height?: number;
};

const useUserInfo = () => {
  const { data } = useSuspenseQuery({
    queryKey: ['userInfo'],
    queryFn: () => getUserInfo(),
  });

  return data;
};

export default function Editor({
  initialData,
  editor: commentEditor,
  setEditor: setCommentEditor,
  onChange,
  height = 400,
}: EditorProps) {
  const postFilesHook = useMutation((props: PostFileReqType) => postFilesApi.POST(props));

  const isWebView = getIsMobileWebview();

  const [permission, setPermission] = useState(!isWebView);

  const postMediaPermission = useCallback(() => {
    if (window.ReactNativeWebView)
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'req-media-permission' }));
  }, []);

  useEffect(() => {
    const clickMediaIconBeforePermission = (e: Event) => {
      e.preventDefault();
      postMediaPermission();
    };

    const observer = new MutationObserver(() => {
      const fileButton = document.querySelector<HTMLButtonElement>('.ck-file-dialog-button');
      if (!fileButton) return;

      observer.disconnect(); // fileButton을 찾으면 더 이상 관찰하지 않음

      if (!permission) {
        fileButton.addEventListener('click', clickMediaIconBeforePermission);
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      document
        .querySelector<HTMLButtonElement>('.ck-file-dialog-button')
        ?.removeEventListener('click', clickMediaIconBeforePermission);
      observer.disconnect();
    };
  }, [permission, postMediaPermission]);

  useEffect(() => {
    const getMediaPermission = (event: MessageEvent<{ type: string }>) => {
      const { type } = event.data;
      if (type === 'res-media-permission') {
        setPermission(true);
      }
    };
    window.addEventListener('message', getMediaPermission);
    return () => {
      window.removeEventListener('message', getMediaPermission);
    };
  }, []);

  useEffect(() => {
    // 웹뷰일 시 초기 상태 업데이트 진행
    if (isWebView) postMediaPermission();
  }, [isWebView, postMediaPermission]);
  const customUploadAdapter = (loader: FileLoader) => {
    return {
      upload(): Promise<UploadResponse> {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            if (!file) return;
            formData.append('file', file);
            formData.append('repository', 'community');
            formData.append('fileName', file.name);

            postFilesHook.mutate(formData, {
              onSuccess: ({ data }) => {
                resolve({
                  default: `${data.fileUrl}`,
                });
              },
            });
          });
        });
      },
    };
  };

  function uploadPlugin(editor: CustomCKEditor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: FileLoader) => {
      return customUploadAdapter(loader);
    };
  }

  return (
    <div className={`prose w-full ${!isBrowser && 'border-Base-Gray border-b-[1px] pt-2'}`}>
      <CKEditor
        editor={CustomCKEditor}
        data='<p> </p>'
        config={{
          // @ts-ignore
          extraPlugins: [uploadPlugin],
          removePlugins: ['MediaEmbed'],
          language: 'ko',
        }}
        onReady={(editor) => {
          setCommentEditor?.(editor);
          editor.setData(initialData);
          editor.editing.view.change((writer) => {
            writer.setStyle('height', `${height}px`, editor.editing.view.document.getRoot()!);
          });

          // 아래 로직들은 모바일에만 적용
          if (isBrowser) return;

          editor.editing.view.document.on('clipboardInput', (event, data) =>
            disableHTMLPasteOnMobile({ editor, event, data }),
          );

          overrideDropdownPositionsToNorth({ editor, toolbarView: editor.ui.view.toolbar });
          overrideGroupDropDownPositionsToNorth({
            editor,
            viewCollections: editor.ui.view.toolbar.children,
          });
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data);
        }}
      />
    </div>
  );
}
