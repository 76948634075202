import { Input, Select, SelectItem } from '@nextui-org/react';
import Spacing from '@simppl/component/Spacing';
import type { PostMockUserAdmissionsReqType } from '@simppl/repository/admins/req';

type AddSubjectDataProps = {
  targetName: string;
  score: Pick<
    PostMockUserAdmissionsReqType['admissionInfo'][number],
    'maxScore' | 'minScore' | 'maxSerializedScore' | 'minSerializedScore' | 'peopleNum'
  >;
  onChange: (
    score: Pick<
      PostMockUserAdmissionsReqType['admissionInfo'][number],
      'maxScore' | 'minScore' | 'maxSerializedScore' | 'minSerializedScore' | 'peopleNum'
    >,
  ) => void;
};

function AddSubjectData({ targetName, onChange, score }: AddSubjectDataProps) {
  return (
    <div className='max-h-[700px] overflow-auto'>
      <div className='w-full px-6'>
        <div className='flex justify-center'>
          <span>{targetName}</span>
        </div>
        <Input
          type='number'
          label='인원 수'
          placeholder='0'
          className='w-fit'
          labelPlacement='outside'
          value={score?.peopleNum?.toString() ?? undefined}
          onChange={(e) => onChange({ ...score, peopleNum: Number(e.target.value) })}
        />
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='최소 총점'
            placeholder='최소 총점'
            value={score?.minScore?.toString() ?? undefined}
            onChange={(e) => {
              console.log(e.target.value);
              onChange({ ...score, minScore: Number(e.target.value) });
            }}
          />
          <Input
            labelPlacement='outside'
            label='최대 총점'
            placeholder='최대 총점'
            value={score?.maxScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxScore: Number(e.target.value),
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='localScore 최솟값'
            placeholder='localScore'
            value={score?.minSerializedScore?.localScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  localScore: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='localScore 최댓값'
            placeholder='localScore'
            value={score?.maxSerializedScore?.localScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  localScore: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='toeflScore 최솟값'
            placeholder='toeflScore'
            value={score?.minSerializedScore?.toeflScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  toeflScore: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='toeflScore 최댓값'
            placeholder='toeflScore'
            value={score?.maxSerializedScore?.toeflScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  toeflScore: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='ieltsScore 최솟값'
            placeholder='ieltsScore'
            value={score?.minSerializedScore?.ieltsScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  ieltsScore: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='ieltsScore 최댓값'
            placeholder='ieltsScore'
            value={score?.maxSerializedScore?.ieltsScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  ieltsScore: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='tepsScore 최솟값'
            placeholder='tepsScore'
            value={score?.minSerializedScore?.tepsScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  tepsScore: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='tepsScore 최댓값'
            placeholder='tepsScore'
            value={score?.maxSerializedScore?.tepsScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  tepsScore: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='toeicScore 최솟값'
            placeholder='toeicScore'
            value={score?.minSerializedScore?.toeicScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  toeicScore: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='toeicScore 최댓값'
            placeholder='toeicScore'
            value={score?.maxSerializedScore?.toeicScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  toeicScore: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Select
            value={score?.minSerializedScore?.hskRank || undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  hskRank: (e.target.value as HSKDegree) || null,
                },
              })
            }
            labelPlacement='outside'
            label='hskRank 최솟값'
            placeholder='hskRank'
          >
            <>
              <SelectItem
                key=''
                value=''
              >
                없음
              </SelectItem>
              {[
                { title: '1급', value: 1 },
                { title: '2급', value: 2 },
                { title: '3급', value: 3 },
                { title: '4급', value: 4 },
                { title: '5급', value: 5 },
                { title: '6급', value: 6 },
              ].map((grade) => (
                <SelectItem
                  key={grade.value}
                  value={grade.value}
                >
                  {grade.title}
                </SelectItem>
              ))}
            </>
          </Select>
          <Select
            value={score?.maxSerializedScore?.hskRank || undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  hskRank: (e.target.value as HSKDegree) || null,
                },
              })
            }
            labelPlacement='outside'
            label='hskRank 최댓값'
            placeholder='hskRank'
          >
            <>
              <SelectItem
                key=''
                value=''
              >
                없음
              </SelectItem>
              {[
                { title: '1급', value: 1 },
                { title: '2급', value: 2 },
                { title: '3급', value: 3 },
                { title: '4급', value: 4 },
                { title: '5급', value: 5 },
                { title: '6급', value: 6 },
              ].map((grade) => (
                <SelectItem
                  key={grade.value}
                  value={grade.value}
                >
                  {grade.title}
                </SelectItem>
              ))}
            </>
          </Select>
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='hskScore 최솟값'
            placeholder='hskScore'
            value={score?.minSerializedScore?.hskScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  hskScore: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='hskScore 최댓값'
            placeholder='hskScore'
            value={score?.maxSerializedScore?.hskScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  hskScore: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Select
            value={score?.minSerializedScore?.deleRank ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  deleRank: (e.target.value as EuropeanDegree) || null,
                },
              })
            }
            labelPlacement='outside'
            label='deleRank 최솟값'
            placeholder='deleRank'
          >
            <>
              <SelectItem
                key=''
                value=''
              >
                없음
              </SelectItem>
              {['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map((grade) => (
                <SelectItem
                  key={grade}
                  value={grade}
                >
                  {grade}
                </SelectItem>
              ))}
            </>
          </Select>
          <Select
            value={score?.maxSerializedScore?.deleRank ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  deleRank: (e.target.value as EuropeanDegree) || null,
                },
              })
            }
            labelPlacement='outside'
            label='deleRank 최댓값'
            placeholder='deleRank'
          >
            <>
              <SelectItem
                key=''
                value=''
              >
                없음
              </SelectItem>
              {['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map((grade) => (
                <SelectItem
                  key={grade}
                  value={grade}
                >
                  {grade}
                </SelectItem>
              ))}
            </>
          </Select>
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='deleScore 최솟값'
            placeholder='deleScore'
            value={score?.minSerializedScore?.deleScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  deleScore: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='deleScore 최댓값'
            placeholder='deleScore'
            value={score?.maxSerializedScore?.deleScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  deleScore: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Select
            value={score?.minSerializedScore?.delfRank ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  delfRank: (e.target.value as EuropeanDegree) || null,
                },
              })
            }
            labelPlacement='outside'
            label='delfRank 최솟값'
            placeholder='delfRank'
          >
            <>
              <SelectItem
                key=''
                value=''
              >
                없음
              </SelectItem>
              {['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map((grade, index) => (
                <SelectItem
                  key={grade}
                  value={grade}
                >
                  {grade}
                </SelectItem>
              ))}
            </>
          </Select>
          <Select
            value={score?.maxSerializedScore?.delfRank ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  delfRank: (e.target.value as EuropeanDegree) || null,
                },
              })
            }
            labelPlacement='outside'
            label='delfRank 최댓값'
            placeholder='delfRank'
          >
            <>
              <SelectItem
                key=''
                value=''
              >
                없음
              </SelectItem>
              {['A1', 'A2', 'B1', 'B2', 'C1', 'C2'].map((grade, index) => (
                <SelectItem
                  key={grade}
                  value={grade}
                >
                  {grade}
                </SelectItem>
              ))}
            </>
          </Select>
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='delfScore 최솟값'
            placeholder='delfScore'
            value={score?.minSerializedScore?.delfScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  delfScore: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='delfScore 최댓값'
            placeholder='delfScore'
            value={score?.maxSerializedScore?.delfScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  delfScore: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='ibScore 최솟값'
            placeholder='ibScore'
            value={score?.minSerializedScore?.ibScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  ibScore: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='ibScore 최댓값'
            placeholder='ibScore'
            value={score?.maxSerializedScore?.ibScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  ibScore: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='ibSubjectCount 최솟값'
            placeholder='ibSubjectCount'
            value={score?.minSerializedScore?.ibSubjectCount?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  ibSubjectCount: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='ibSubjectCount 최댓값'
            placeholder='ibSubjectCount'
            value={score?.maxSerializedScore?.ibSubjectCount?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  ibSubjectCount: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='apScore 최솟값'
            placeholder='apScore'
            value={score?.minSerializedScore?.apScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  apScore: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='apScore 최댓값'
            placeholder='apScore'
            value={score?.maxSerializedScore?.apScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  apScore: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='apSubjectCount 최솟값'
            placeholder='apSubjectCount'
            value={score?.minSerializedScore?.apSubjectCount?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  apSubjectCount: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='apSubjectCount 최댓값'
            placeholder='apSubjectCount'
            value={score?.maxSerializedScore?.apSubjectCount?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  apSubjectCount: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='satScore 최솟값'
            placeholder='satScore'
            value={score?.minSerializedScore?.satScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  satScore: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='satScore 최댓값'
            placeholder='satScore'
            value={score?.maxSerializedScore?.satScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  satScore: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='aLevelScore 최솟값'
            placeholder='aLevelScore'
            value={score?.minSerializedScore?.aLevelScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  aLevelScore: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='aLevelScore 최댓값'
            placeholder='aLevelScore'
            value={score?.maxSerializedScore?.aLevelScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  aLevelScore: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='aLevelSubjectCount 최솟값'
            placeholder='aLevelSubjectCount'
            value={score?.minSerializedScore?.aLevelSubjectCount?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  aLevelSubjectCount: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='aLevelSubjectCount 최댓값'
            placeholder='aLevelSubjectCount'
            value={score?.maxSerializedScore?.aLevelSubjectCount?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  aLevelSubjectCount: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='actScore 최솟값'
            placeholder='actScore'
            value={score?.minSerializedScore?.actScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  actScore: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='actScore 최댓값'
            placeholder='actScore'
            value={score?.maxSerializedScore?.actScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  actScore: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='awardScore 최솟값'
            placeholder='awardScore'
            value={score?.minSerializedScore?.awardScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  awardScore: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='awardScore 최댓값'
            placeholder='awardScore'
            value={score?.maxSerializedScore?.awardScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  awardScore: Number(e.target.value),
                },
              })
            }
          />
        </div>
        <Spacing height={16} />
        <div className='flex gap-x-4'>
          <Input
            labelPlacement='outside'
            label='activityScore 최솟값'
            placeholder='activityScore'
            value={score?.minSerializedScore?.activityScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                minSerializedScore: {
                  ...score.minSerializedScore,
                  activityScore: Number(e.target.value),
                },
              })
            }
          />
          <Input
            labelPlacement='outside'
            label='activityScore 최댓값'
            placeholder='activityScore'
            value={score?.maxSerializedScore?.activityScore?.toString() ?? undefined}
            onChange={(e) =>
              onChange({
                ...score,
                maxSerializedScore: {
                  ...score.maxSerializedScore,
                  activityScore: Number(e.target.value),
                },
              })
            }
          />
        </div>
      </div>
    </div>
  );
}
export default AddSubjectData;
