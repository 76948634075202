import axios from '..';
import type {
  DeleteUniversitiesReqType,
  GetAdmissionsInformationReqType,
  GetUniversitiesReqType,
  PostMainUniversitiesReqType,
} from './req';
import type {
  DeleteUniversitiesResType,
  GetAdmissionsInformationResType,
  GetUniversitiesResType,
  PostMainUniversitiesResType,
} from './res';

export const mainUniversities = {
  post: (body: PostMainUniversitiesReqType) =>
    axios
      .post<PostMainUniversitiesResType>('/universities', { ...body, isMain: true })
      .then((res) => res.data),
};

export const universities = {
  get: (params: GetUniversitiesReqType) =>
    axios.get<GetUniversitiesResType>('/universities', { params }).then((res) => res.data),
  delete: ({ universityId }: DeleteUniversitiesReqType) =>
    axios
      .delete<DeleteUniversitiesResType>(`/universities/${universityId}`)
      .then((res) => res.data),
};

export const universitiesAdmissions = {
  get: ({ universityId }: GetAdmissionsInformationReqType) =>
    axios
      .get<GetAdmissionsInformationResType>(`/universities/${universityId}/admissions`)
      .then((res) => res.data),
};
