import axios from '..';
import type { PostFileReqType, PostScoreFilesReqType } from './req';
import type { PostFilesResType, PostScoreFilesResType } from './res';

export const postFilesApi = {
  POST: async (body: PostFileReqType) => {
    checkFileRequestFormData(body);
    return (await axios.post<PostFilesResType>('/files', body)).data;
  },
  SCORE: {
    POST: async (body: PostScoreFilesReqType) =>
      (await axios.post<PostScoreFilesResType>('/files/score', body)).data,
  },
};

// FormData 타입 체크가 안되서 검증용..
const checkFileRequestFormData = (formData: FormData) => {
  if (!formData.get('repository')) throw Error('repository가 없습니다');
  if (!formData.get('fileName')) throw Error('fileName이 없습니다');
  if (!formData.get('file')) throw Error('file이 없습니다');
};
