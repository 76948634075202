import useMutation from '@simppl/react-query/useMutation';
import useQueryClient from '@simppl/react-query/useQueryClient';
import useSuspenseQuery from '@simppl/react-query/useSuspenseQuery';
import { admissionApplyCountApi } from '@simppl/repository/admission';
import type {
  DeleteAdmissionApplyCountReqType,
  PatchAdmissionApplyCountReqType,
  PostAdmissionApplyCountReqType,
} from '@simppl/repository/admission/req';
import { useState } from 'react';

export const useAdmissionApplyCounts = () => {
  const queryClient = useQueryClient();

  const { data: admissionApplyCountList } = useSuspenseQuery({
    queryKey: ['get-admission-apply-count'],
    queryFn: () => admissionApplyCountApi.get(),
  });

  const [admissiomApplyCountModalData, setAdmissiomApplyCountModalData] = useState<
    { mode: 'create' } | { mode: 'update'; data: PatchAdmissionApplyCountReqType }
  >({ mode: 'create' });

  const postAdmissionApplyCountHook = useMutation(admissionApplyCountApi.post);
  const patchAdmissionApplyCountHook = useMutation(admissionApplyCountApi.patch);
  const deleteAdmissionApplyCountHook = useMutation(admissionApplyCountApi.delete);

  const postAdmissionApplyCount = (body: PostAdmissionApplyCountReqType) =>
    postAdmissionApplyCountHook.mutate(body, { onSuccess: inValidateGetAdmissionApplyCount });

  const patchAdmissionApplyCount = (body: PatchAdmissionApplyCountReqType) =>
    patchAdmissionApplyCountHook.mutate(body, { onSuccess: inValidateGetAdmissionApplyCount });

  const deleteAdmissionApplyCount = ({ id }: DeleteAdmissionApplyCountReqType) =>
    deleteAdmissionApplyCountHook.mutate(
      { id },
      {
        onSuccess: inValidateGetAdmissionApplyCount,
      },
    );

  const inValidateGetAdmissionApplyCount = () => {
    queryClient.invalidateQueries(['get-admission-apply-count']);
  };

  const onSaveAdmissionApplyCountModal = (
    body: PostAdmissionApplyCountReqType | PatchAdmissionApplyCountReqType,
  ) => {
    switch (admissiomApplyCountModalData.mode) {
      case 'create':
        postAdmissionApplyCount(body);
        break;
      case 'update':
        patchAdmissionApplyCount(body);
        break;
      default:
        alert('개발자에게 문의하세요.');
    }
  };

  return {
    admissionApplyCountList: admissionApplyCountList || [],
    onSaveAdmissionApplyCountModal,
    setAdmissiomApplyCountModalData,
    deleteAdmissionApplyCount,
    admissiomApplyCountModalData,
  };
};
