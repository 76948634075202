import axios from '..';
import type { DeleteBadgesReqType, PatchBadgesReqType, PostBadgesReqType } from './req';
import type {
  DeleteBadgesResType,
  GetBadgesResType,
  PatchBadgesResType,
  PostBadgesResType,
} from './res';

export const badgeApi = {
  GET: async () => (await axios.get<GetBadgesResType>('/badges')).data,
  POST: async (body: PostBadgesReqType) =>
    (await axios.post<PostBadgesResType>('/badges', body)).data,
  PATCH: async (body: PatchBadgesReqType) =>
    (await axios.patch<PatchBadgesResType>('/badges', body)).data,
  DELETE: async (body: DeleteBadgesReqType) =>
    (await axios.delete<DeleteBadgesResType>(`/badges/${body.id}`)).data,
};
